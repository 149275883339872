.documents-container
  text-align: center
  h3
    padding: 40px
    margin-top: 105px
    background: #417fb3
    color: #ffffff
    font-size: 5rem
    margin-bottom: 80px

.documents
  display: flex
  flex-direction: column
  justify-content: center
  align-content: center
  gap: 20px
  padding-bottom: 20px
  a
    text-decoration: none
    font-size: 2.2rem
    transition: .2s
    color: #111111
    &:hover
      color: #378ddd

.documents
  div
    h1
      font-size: 3rem
      font-weight: bold
      text-transform: uppercase
    svg
      font-size: 1.8rem
      margin-top: -5px
      margin-left: 2px

.admin-container
  text-align: center
  h3
    padding: 40px
    margin-top: 105px
    background: #7a1717
    color: #ffffff
    font-size: 5rem
    margin-bottom: 20px
  h4
    font-size: 3rem

.user-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  p
    font-size: 1.7rem
  .user-title
    padding-top: 10px
    width: 500px
    justify-content: space-around
    display: flex
    border: 3px solid black
  .user-row
    position: relative
    padding-top: 6px
    width: 500px
    justify-content: space-around
    display: flex
    border: 3px solid black
    border-top: none
    svg
      position: absolute
      right: 6px
      top: 10px
      font-size: 1.8rem
      color: #c30909
      cursor: pointer
      transition: .2s
      &:hover
        color: #ff3b3b

.add-user
  display: flex
  justify-content: center
  align-items: center
  margin: 20px 0 25px 0
  input
    padding-left: 4px
    font-size: 1.6rem
    border: 3px solid #811717
  input:nth-of-type(1)
    border-right: none
  button
    font-size: 1.6rem
    padding: 0 10px
    background: #ca2828
    border: 3px solid #2a2a2a
    color: #ffffff
    font-weight: bold
    text-shadow: 1px 1px 1px black
    margin-left: 5px
