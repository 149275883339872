.home
  animation: slide-down 1s

#contact
  position: absolute
  margin-top: -630px

.image-slider
  position: relative
  margin-top: 25px
  z-index: 0
  width: 100%
  height: 630px
  display: flex
  justify-content: center
  align-items: center
  overflow-x: hidden
  background: #131313

.carousel
  position: static

.carousel-control-prev
  span:nth-of-type(2)
    display: none

.carousel-control-next
  span:nth-of-type(2)
    display: none

.carousel-control-next-icon, .carousel-control-prev-icon
  width: 6rem
  height: 6rem

.carousel-item, .carousel-inner
  position: static

.carousel-caption
  top: 50%
  transform: translateY(-50%)
  bottom: auto
  .header-title
    font-weight: bold
    font-size: 6rem
    text-transform: uppercase
    text-shadow: 1px 1px 2px #000
    margin-bottom: 15px
  .header-description
    font-size: 3rem
    width: 800px
    text-shadow: 1px 1px 1px #000
    text-align: center
    margin: auto
  .header-subtitle
    font-family: 'Playfair Display', serif
    letter-spacing: 1.5px
    font-style: italic
    font-size: 2.5rem
    text-shadow: 1px 1px 1px #000
  .header-subdescription
    font-weight: bold
    text-transform: uppercase
    font-size: 6.5rem
    text-shadow: 1px 1px 2px #000

.services
  background: repeating-linear-gradient(-50deg, #283033, #0f1011 3px,#0f1011 3px, #283033 12px)
  display: flex
  justify-content: space-evenly
  align-items: center
  padding: 70px 0
  div:nth-child(1) svg
    background: #00c984
  div:nth-child(2) svg
    background: #ff8255
  div:nth-child(3) svg
    background: #ffaf02
  div:nth-child(4) svg
    background: #4e6bbc
  div
    display: flex
    flex-direction: column
    align-items: center
    width: 220px
    gap: 10px
    svg
      color: #fff
      font-size: 13rem
      border-radius: 50%
      padding: 37px
      margin-bottom: 25px
    h3
      color: #fff
      font-size: 2.8rem
      text-transform: uppercase
      font-weight: normal
      text-align: center
    p
      color: #fff
      font-size: 1.5rem
      text-align: center
      line-height: 1.5

.latest-news
  margin: 70px 0 70px 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  h3
    text-align: center
    font-size: 5rem
    margin-bottom: 30px
    font-weight: bold

.latest-news-container
  display: flex
  justify-content: space-evenly
  width: 900px
  .latest-news-contact
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px
    width: 200px
    padding: 20px
    div, a
      border-radius: 50%
      border: 5px solid #444444
      padding: 10px
      width: 100px
      height: 100px
      display: flex
      align-items: center
      justify-content: center
      transition: .3s
      &:hover
        border: 5px solid #3c8cc2
    svg
      font-size: 6rem
      color: #1b1b1b
      z-index: 2
    p
      text-align: center
      font-size: 1.7rem
      font-style: italic
      color: #1b1b1b
      z-index: 2
    p:nth-of-type(2)
      margin-top: -26px

.clients-container
  padding: 50px 0 40px 0
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  h4
    font-size: 5rem
    text-transform: uppercase
    color: #000
    font-weight: bold

.clients
  width: 100%
  margin-top: 10px
  display: flex
  justify-content: space-evenly
  div
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    width: 280px
    img
      border-radius: 50%
      margin-bottom: 20px
    h3
      text-align: center
      color: #106d92
      font-size: 2.8rem
    p
      text-align: center
      font-size: 1.4rem
    svg
      color: #106d92
      font-size: 4.5rem

.partner-container
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 70px
  h3
    background: #106d92
    width: 100%
    text-align: center
    color: #fff
    padding: 40px 0
    font-size: 4rem
    text-transform: uppercase
    font-weight: bold

.partner-slideshow
  margin-top: 50px
  width: 100vw
  overflow-x: hidden
  .partner-images
    display: flex
    cursor: pointer
    animation: infiniteSlide 30s linear infinite
    &:hover
      animation-play-state: paused
    a
      width: 20vw
      flex-shrink: 0

@keyframes infiniteSlide
  0%
    transform: translateX(0vw)
  100%
    transform: translateX(-200vw)

@media ( max-width: 1400px )
  .partner-slideshow
    .partner-images
      a
        img
          width: 100%

@media ( max-width: 1300px )
  .image-slider
    height: 400px
    overflow: hidden

  .services
    padding: 25px
    flex-wrap: wrap
    gap: 30px
    div
      width: calc(50% - 30px)

@media ( max-width: 1000px )
  .latest-news-container
    width: 100%
    flex-wrap: wrap
    .latest-news-contact
      width: 50%
      div, a
        width: 80px
        height: 80px
        svg
          font-size: 4rem

  .clients
    flex-direction: column
    align-items: center
    gap: 30px
    div
      svg
        display: none

  .services
    div
      p
        display: none

  .partner-slideshow
    .partner-images
      animation: infiniteSlideTablet 25s linear infinite
      a
        width: 30vw

  @keyframes infiniteSlideTablet
    0%
      transform: translateX(0vw)
    100%
      transform: translateX(-300vw)

@media ( max-width: 760px )
  .background-video
    .video-title
      h2
        font-size: 2.5rem
      p
        font-size: 1.4rem
        padding: 0 10px
        width: auto

  #contact
    margin-top: -760px

  .latest-news-container
    width: 100%

  .image-slider
    height: 300px

  .carousel-caption
    .header-title
      font-size: 2.4rem
    .header-description
      display: none
    .header-subtitle
      font-size: 2.8rem
    .header-subdescription
      font-size: 2.4rem

  .latest-news
    h3
      font-size: 3rem

  .latest-news-container
    flex-direction: column
    align-items: center

  .services
    div
      width: calc(100% - 30px)

  .clients-container
    h4
      margin-top: -10px
      text-align: center
      font-size: 2.4rem

  .partner-slideshow
    margin-top: 40px
    .partner-images
      animation: infiniteSlideMobile 25s linear infinite
      a
        width: 50vw

  @keyframes infiniteSlideMobile
    0%
      transform: translateX(0vw)
    100%
      transform: translateX(-500vw)
