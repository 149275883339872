.artist-profile
  margin-top: 25px
  animation: slide-down 1s
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden
  header
    filter: blur(8px)
    height: 410px
    width: 105%
    margin-top: -10px
    background-position: center
    background-size: cover
    &::after
      content: ''
      position: absolute
      height: 410px
      width: 105%
      background: #00000032

.artist-profile-details
  margin-top: -235px
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  gap: 10px
  .artist-musicIcon
    background: #103685
    width: 90px
    border-radius: 50%
    margin-top: -50px
    svg
      width: 90px
      height: 90px
      padding: 25px
      color: #ffffff
  h2
    font-size: 2.8rem
    font-weight: bold

.artist-picture
  overflow: hidden
  height: 295px
  max-width: 350px
  border: 2px solid #103685
  border-radius: 5px
  box-shadow: 0px 5px 10px #000000
  display: flex
  align-items: start
  justify-content: center
  background: #000
  img
    width: 350px
    height: 295px
    object-fit: cover
    object-position: top

.artist-profile-description, .carousel
  display: flex
  position: relative

.artist-profile-slider
  width: 620px
  height: 420px
  background: #000

.artist-profile-slider .carousel-item img
  width: 620px
  height: 420px
  object-fit: contain
  object-position: center

.artist-profile-description
  display: flex
  align-items: center
  margin-top: 50px
  gap: 10px
  width: 1200px
  .artist-profile-text
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    a
      text-decoration: none
      background: #20ce88
      font-size: 1.5rem
      text-transform: uppercase
      padding: 8px 10px
      color: #fff
      border: none
      margin-top: 10px
  p
    font-size: 2rem
    text-align: center
    padding: 10px 20px 0px 20px
  p:nth-of-type(2)
    font-style: italic

.artist-profile-video
  margin-top: 80px
  display: flex
  justify-content: space-between
  align-items: center
  gap: 100px

iframe
  width: 450px
  height: 255px

@media ( max-width: 1300px )
  .artist-profile-description
    width: 100%
    padding: 0 20px
    margin-top: 30px
    .artist-profile-text
      p
        font-size: 1.4rem
    button
      font-size: 1.4rem
      padding: 4px 7px

  .artist-profile-slider
    width: 300px
    height: 210px

  .artist-profile-slider .carousel-item img
    width: 300px
    height: 210px

  .artist-profile-video
    justify-content: center
    margin-top: 55px
    gap: 30px
    margin: 50px 0 -20px 0
    div
      display: flex
      justify-content: center
      align-items: center

  iframe
    width: 300px
    height: 175px

@media ( max-width: 760px )
  .artist-profile-details
    margin-top: -170px

  .artist-picture
    max-width: 280px
    height: 260px

  .artist-profile
    margin-top: 0
    header
      height: 310px
      &::after
        height: 310px

  .artist-profile-description
    flex-direction: column
    margin-top: 10px
    gap: 30px

  .artist-profile-video
    flex-direction: column
    margin: 40px 0 -10px 0
