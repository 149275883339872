.artists-category
  display: flex
  justify-content: center
  align-items: center
  gap: 30px
  width: 780px
  margin: 70px auto 0 auto
  flex-wrap: wrap
  a
    cursor: pointer
    height: 370px
    width: 370px
    position: relative
    overflow: hidden
    z-index: 1
  p
    z-index: 2
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    text-align: center
    font-size: 2.6rem
    font-weight: bold
    text-transform: uppercase
    color: #fff
    text-shadow: 1px 1px 1px #000
  img
    opacity: .8

.tile-container
  width: 100%
  height: 100%
  background: #000000
  transition: .5s
  display: flex
  justify-content: center
  &:hover
    transform: scale(1.1)
  img
    height: 370px
    width: 370px
    object-fit: cover

.artists-subcategory
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 30px
  width: 1021px
  margin: 50px auto 0 auto
  flex-wrap: wrap
  a
    cursor: pointer
    height: 320px
    width: 320px
    position: relative
    overflow: hidden
  p
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 2.2rem
    text-align: center
    font-weight: bold
    text-transform: uppercase
    color: #fff
    text-shadow: 1px 1px 1px #000
  img
    opacity: .8

@media ( max-width: 1300px )
  .artists-category
    padding: 20px
    width: 100%
    margin: 50px auto 0 auto
    a
      width: 300px
      height: 300px

  .artists-subcategory
    width: 100%
    justify-content: center
    a
      width: 300px
      height: 300px

@media ( max-width: 760px )
  .artists-category
    padding: 0
    flex-direction: column
    margin: 25px auto 0 auto
