@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap')

@import ./Home.sass
@import ./Navbar.sass
@import ./Footer.sass
@import ./Artists.sass
@import ./Artistprofile.sass
@import ./About.sass
@import ./Contact.sass
@import ./Signed.sass

*
  font-size: 10px
  margin: 0
  padding: 0
  box-sizing: border-box
  text-decoration: none
  font-family: 'Roboto', sans-serif
  scroll-behavior: smooth

body
  color: #2f2e2e
  overflow-x: hidden

@keyframes slide-down
  0%
    opacity: 0
  100%
    opacity: 1

video
  object-fit: cover
  width: 100%
  height: 100vh
  z-index: -1
  top: 0
  left: 0
  opacity: .7
  overflow: hidden
  animation: videoFadeIn 1s

@keyframes videoFadeIn
  0%
    opacity: 0
  100%
    opacity: .7

.background-video
  position: relative
  background: #000000

.video-title
  text-align: center
  color: #fff
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-shadow: 1px 1px 1px #000
  width: 100%
  h2
    font-size: 5.7rem
    text-transform: uppercase
  p
    font-size: 2.4rem
    width: 600px
    margin: auto
  div
    margin-top: 17px
    position: absolute
    background: #444343b2
    border: 2px solid #fff
    border-radius: 50%
    padding: 5px
    left: 50%
    transform: translateX(-50%)
    transition: .2s
    &:hover
      cursor: pointer
      background: #727272
    svg
      color: #ffffff
      font-size: 5rem
      padding-left: 2px
      padding-top: 1px

.section-header
  margin-top: 25px
  animation: slide-down 1s
  header
    height: 410px
    width: 100%
    background-position: center
    background-size: cover
    display: flex
    justify-content: center
    align-items: center
    &::after
      content: ''
      position: absolute
      height: 410px
      width: 100%
      background: #00000032
    h2
      z-index: 2
      font-size: 5rem
      font-weight: bold
      text-transform: uppercase
      color: #fff
      text-shadow: 2px 2px 1px #000000

.back-button, .back-button-artist
  background: none
  border: none
  font-size: 5rem
  color: #104877
  position: absolute
  top: 450px
  left: 15px
  cursor: pointer
  transition: .3s
  &:hover
    color: #2e72aa

@media ( max-width: 1300px )
  .back-button, .back-button-artist
    left: 8px
    margin-top: -5px

@media ( max-width: 760px )
  .section-header
    header
      height: 250px
      margin-top: -25px
      &::after
        height: 250px
      h2
        margin-top: 35px
        font-size: 3rem
        text-align: center

  .back-button, .back-button-artist
    position: fixed
    z-index: 20
    top: 14px
    left: 10px
    color: #fff
