.contact-container
  display: flex
  margin-bottom: -70px
  div
    width: 50%
    display: flex
    justify-content: center
    color: #fff
    height: 100vh
    align-items: center
    flex-direction: column
  div:nth-of-type(1)
    background-position: center
    background-size: cover
    text-shadow: 1px 1px 1px #000
    h2
      font-size: 4rem
      text-transform: uppercase
    p
      font-size: 1.7rem
  div:nth-of-type(2)
    background: #0f0f0f
  form
    display: flex
    flex-direction: column
    gap: 15px
    input
      width: 270px
      height: 30px
      border: none
      font-size: 1.5rem
      padding-left: 5px
    textarea
      padding-left: 5px
      padding-top: 2px
      resize: none
      height: 100px
      font-size: 1.5rem
      border: none
    input[type='submit']
      background: #444444
      color: #fff
      height: 35px
      transition: .3s
      font-weight: bold
      &:hover
        background: #666666

@media  ( max-width: 760px )
  .contact-container
    flex-direction: column
    div
      width: 100%
      height: 400px
    div:nth-of-type(1)
      height: 250px
      h2
        margin-top: 45px
