footer
  background: #222222
  margin-top: 70px
  padding-top: 60px
  width: 100%

.footer-contacts
  padding: 0 30px
  display: flex
  justify-content: space-around
  color: #ffffff
  margin-bottom: 40px
  .footer-card
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px
    width: 400px
    img
      margin-top: -35px
    h2
      text-transform: uppercase
      font-size: 2.4rem
      font-weight: bold
      text-align: center
    p
      font-size: 1.3rem
    h3
      font-size: 1.5rem
      font-weight: bold
    h4
      font-size: 1.5rem
      margin-top: -10px

.facebook-container
  .facebook-style
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 10px
    border: 1px solid #d1d1d1
    padding: 7px
    img
      margin-top: 0

.facebook-title
  display: flex
  flex-direction: column
  h5
    font-size: 1.8rem
    font-weight: bold
  a
    display: flex
    align-items: center
    justify-content: center
    gap: 3px
    border: 1px solid #999999
    width: 90px
    padding: 1px
    margin: auto
    text-decoration: none
    background: #ececec
    transition: .3s
    &:hover
      background: #cfcfcf
    svg
      font-size: 1.5rem
      color: #ffffff
      background: #3a5897
      padding: 2px
    p
      margin-bottom: 0
      color: #222222

.footer-copyright
  background: #0f0f0f
  display: flex
  justify-content: center
  p
    color: #ffffff
    margin-bottom: 0
    padding: 20px
    font-size: 1.5rem

@media ( max-width: 1000px )
  footer
    margin-top: 70px

  .footer-contacts
    .footer-card:nth-of-type(1)
      display: none

@media ( max-width: 760px )
  footer
    margin-top: 50px

  .footer-contacts
    .footer-card:nth-of-type(2)
      display: none
    .footer-card
      h2
        margin-top: -15px
        font-size: 2.2rem
