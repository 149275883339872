.about-header
  margin: auto
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  max-width: 800px
  svg
    font-size: 6rem
    background: #00c984
    color: #ffffff
    border-radius: 50%
    padding: 15px
  h3
    margin-top: 50px
    font-size: 3rem
    text-transform: uppercase
    font-weight: bold
  p
    font-size: 2rem
    text-align: center
    margin-bottom: -1px

.about-content
  display: flex
  width: 900px
  margin: 80px auto 0 auto
  align-items: center
  img
    width: 450px
  h4
    font-size: 2.2rem
    text-align: center
    font-weight: bold
  p
    font-size: 2rem
    padding: 10px 30px
    text-align: justify

@media ( max-width: 1000px )
  .about-header
    p
      font-size: 1.6rem

  .about-content
    width: 100%
    padding: 20px
    margin: 30px auto 0 auto
    img
      width: 300px
    p
      font-size: 1.7rem

@media ( max-width: 760px )
  .about-header
    padding: 0 20px
    h3
      font-size: 2rem
    p
      font-size: 1.6rem

  .about-content
    flex-direction: column
    margin: 10px auto 0 auto
    img
      width: 280px
      margin-bottom: 20px
    h4
      font-size: 1.9rem
    p
      font-size: 1.6rem
      margin-bottom: -20px
      padding: 0 5px
