nav
  position: fixed
  top: 0
  left: 0
  z-index: 5
  width: 100%
  // overflow: hidden

.nav-contact
  width: 100vw
  background: #0f0f0f
  height: 25px
  display: flex
  align-items: center
  justify-content: space-evenly
  border-bottom: 2px solid #000
  div
    display: flex
    gap: 7px
    svg
      color: #fff
      font-size: 1.5rem
      line-height: normal
      margin-top: 3px
    p
      color: #fff
      font-size: 1.4rem
      margin-bottom: 0
  .social-icons
    svg
      margin-top: 0px
      font-size: 2rem
      cursor: pointer

.nav-menu
  display: flex
  justify-content: space-evenly
  align-items: center
  background: #181818af
  height: 80px
  img
    position: relative
    top: 4px

.mobile-menu
  position: absolute
  right: 20px
  color: #fff
  font-size: 3rem
  cursor: pointer
  transition: .3s
  &:hover
    color: #858585

.mobile-navlinks
  position: absolute
  border-top: 2px solid #fff
  border-bottom: 2px solid #fff
  background: #000000d0
  top: 65px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  gap: 10px
  padding: 15px 0
  animation: menuFadeIn 1s
  a
    text-decoration: none
    color: #fff
    font-size: 2rem

@keyframes menuFadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.nav-links
  display: flex
  gap: 30px
  .active
    color: #41b4c3
    text-shadow: 1px 1px 1px #000
  a
    color: #fff
    font-size: 1.8rem
    text-transform: uppercase
    transition: .4s
    text-decoration: none
    &:hover
      color: #58c8cc
    &:active
      color: #953c3c

.nav-links
  .login-icon
    svg
      color: #ffffff
      font-size: 2.5rem
      margin-left: -15px
      margin-top: -2px

@media ( min-width: 760px )
  .mobile-menu
    display: none

  .mobile-navlinks
    display: none

@media ( max-width: 760px )
  nav
    width: 100vw

  .nav-contact
    display: none

  .nav-menu
    justify-content: center
    height: 65px
    img
      width: 100px

  .nav-links
    display: none
